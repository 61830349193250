<template>
    <v-app>
        <v-layout>
            <AppBar/>
            <v-main>
                <v-container>
                    <slot></slot>
                </v-container>
            </v-main>
        </v-layout>
    </v-app>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
